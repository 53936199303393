/* eslint-disable max-len */
import * as React from 'react'

import { useTheme } from '@mui/material'

type NavLogoProps = {
  href?: string
  height?: string | number
}

export const NavLogo = ({
  href,
  height,
}: NavLogoProps) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 206.65 51.43'
      fill={useTheme().palette.text.primary}
      height={height}
    >
      <path d='M139.08,35.76c-1.2,1.41-2.46,2.64-4.1,3.45-4.67,2.3-10.73,.97-14.23-3.13-4.85-5.67-4.32-14.02,1.21-19.04,4.15-3.77,11.4-3.94,15.21-.33,.63,.6,1.18,1.28,1.92,2.09,0-1.32,.02-2.44-.01-3.56-.02-.5,.09-.7,.64-.69,1.57,.04,3.13,.02,4.7,0,.42,0,.59,.09,.59,.56-.03,8.4,.07,16.81-.1,25.21-.1,5.01-2.9,8.38-7.55,10.06-4.64,1.67-9.27,1.29-13.67-.9-2.67-1.32-4.37-3.51-4.83-6.54-.11-.74,0-1.11,.9-1.05,1.41,.09,2.83,.02,4.24,.03,.29,0,.64-.09,.73,.36,.5,2.51,2.42,3.44,4.6,3.89,2.33,.47,4.65,.37,6.74-1,1.95-1.27,2.93-3.09,2.99-5.39,.04-1.34,0-2.68,0-4.02Zm-7.91-16.19c-4.41,0-7.88,3.36-7.89,7.65,0,4.22,3.49,7.61,7.84,7.59,4.6-.02,7.92-3.22,7.94-7.64,.01-4.31-3.39-7.59-7.89-7.6Z' />
      <path d='M62.54,22.01c0-5.89,.02-11.78-.02-17.67,0-.78,.22-.94,.97-.94,6.67,.03,13.34,.03,20.01,0,.77,0,1,.2,.96,.96-.06,1.35-.06,2.7,0,4.05,.04,.78-.13,1.04-.99,1.03-4.61-.05-9.22,0-13.83-.04-.78,0-.95,.21-.94,.96,.05,2.58,.05,5.15,0,7.73-.01,.76,.24,.92,.94,.91,4.06-.03,8.11,0,12.17-.04,.79,0,.97,.22,.94,.97-.06,1.38-.05,2.76,0,4.14,.03,.69-.18,.88-.88,.88-4.09-.03-8.18,0-12.26-.03-.74,0-.92,.18-.91,.91,.04,4.54-.01,9.08,.04,13.62,.01,.97-.28,1.18-1.18,1.13-1.41-.08-2.83-.06-4.24,0-.64,.02-.81-.15-.81-.8,.03-5.92,.02-11.84,.02-17.76Z' />
      <path d='M193.14,14.07c7.56-.02,13.53,5.97,13.51,13.57-.02,7.6-5.89,13.46-13.48,13.46-7.59,0-13.42-5.84-13.45-13.48-.02-7.57,5.88-13.53,13.42-13.55Zm-7.56,13.55c0,4.41,3.3,7.78,7.6,7.77,4.31-.01,7.62-3.4,7.6-7.79-.02-4.44-3.36-7.87-7.66-7.86-4.22,.01-7.54,3.48-7.54,7.88Z' />
      <path d='M106.62,36.63c-.65,.77-1.11,1.4-1.66,1.95-2.5,2.5-5.59,2.97-8.87,2.23-4.97-1.13-7.95-5.04-8.03-10.48-.08-5-.02-10-.04-15,0-.56,.13-.74,.71-.72,1.47,.04,2.95,.05,4.42,0,.69-.03,.79,.24,.79,.85-.02,4.33-.01,8.65-.01,12.98,0,2.13,.44,4.08,2.15,5.54,2.02,1.72,4.36,1.8,6.68,.99,2.32-.81,3.48-2.72,3.74-5.13,.26-2.32,.09-4.66,.12-6.99,.02-2.48,.02-4.97,0-7.45,0-.58,.12-.8,.76-.78,1.5,.05,3.01,.03,4.52,0,.49,0,.7,.09,.7,.65-.02,8.22-.01,16.44,0,24.66,0,.44-.06,.66-.59,.64-1.6-.03-3.2-.04-4.79,0-.53,.01-.59-.22-.58-.65,.02-1.03,0-2.06,0-3.28Z' />
      <path d='M169.37,18.01c2.22-3.13,5-4.34,8.54-3.79,.63,.1,.96,.25,.92,1-.08,1.25-.08,2.52,0,3.77,.05,.81-.26,.8-.89,.68-2.2-.42-4.26-.14-6.08,1.3-1.64,1.3-2.4,3.04-2.43,5.04-.08,4.51-.06,9.02-.04,13.53,0,.77-.12,1.07-.98,1.02-1.44-.09-2.89-.04-4.33-.01-.49,.01-.62-.13-.61-.62,.01-8.22,.01-16.45,0-24.67,0-.53,.16-.68,.68-.67,1.54,.03,3.07,.03,4.61,0,.48,0,.65,.13,.63,.62-.03,.85,0,1.69,0,2.78Z' />
      <path d='M157.18,27.66c0,4.02-.02,8.04,.02,12.06,0,.71-.2,.87-.88,.85-1.41-.05-2.83-.04-4.24,0-.56,.01-.82-.09-.82-.74,.02-8.16,.02-16.32,0-24.48,0-.61,.21-.79,.8-.78,1.41,.03,2.83,.06,4.24,0,.79-.04,.89,.28,.89,.96-.03,4.05-.01,8.1-.01,12.15Z' />
      <path d='M154.28,10.13c-2.11,0-3.76-1.67-3.76-3.81,0-2,1.68-3.69,3.67-3.71,2.11-.02,3.87,1.67,3.88,3.72,0,2.1-1.69,3.8-3.79,3.81Z' />
      <rect
        x='0'
        y='19.05'
        width='6.22'
        height='30.52'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='0'
        y='0'
        width='6.22'
        height='15.26'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='9.66'
        y='0'
        width='6.22'
        height='10.19'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='9.66'
        y='39.38'
        width='6.22'
        height='10.19'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='9.66'
        y='4'
        width='6.22'
        height='31.58'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='19.31'
        y='35.49'
        width='6.22'
        height='14.08'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='19.25'
        y='10.02'
        width='6.22'
        height='21.7'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='19.25'
        y='0'
        width='6.22'
        height='17.41'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='28.9'
        y='25.3'
        width='6.22'
        height='24.27'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='28.9'
        y='15.16'
        width='6.22'
        height='6.22'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='28.9'
        y='0'
        width='6.22'
        height='21.37'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='38.34'
        y='0'
        width='6.22'
        height='34.83'
        rx='2.41'
        ry='2.41'
      />
      <rect
        x='38.34'
        y='38.73'
        width='6.22'
        height='10.53'
        rx='2.41'
        ry='2.41'
      />
    </svg>
  </a>
)

export default NavLogo
