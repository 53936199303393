import * as React from 'react'

import { Complaints } from 'shared/pages'

import Layout from '../layouts/landing'

const ComplaintPage = () => (
  <Layout>
    <Complaints />
  </Layout>
)

export default ComplaintPage
