import * as React from 'react'

import { NavBar as BaseNavBar } from 'shared/components'

import NavLogo from './nav_logo'

import type { NavBarProps as BaseNavBarProps } from 'shared/components'

type NavBarProps = Omit<BaseNavBarProps, 'navLogo' | 'sx' | 'themePaletteSwitch'> & {
  rootPath?: string
}

const NavBar = ({
  rootPath,
  children,
  ...rest
}: NavBarProps) => (
  <BaseNavBar
    navLogo={(
      <NavLogo
        href={rootPath}
        height='32px'
      />
    )}
    sx={{ px: 2 }}
    {...rest}
  >
    {children}
  </BaseNavBar>
)

export default NavBar
